import React, { Component } from 'react';
import { toPage } from '../../router/history';


interface NavItem {
    active: boolean,
    name: string,
    key: string,
}
export class NavComponent extends Component {
    state = {
        navList: [
            {
                active: true,
                name: "主页",
                key: "Home",
            }, {
                active: false,
                name: "关于我们",
                // key: "About",
                key: "Detail/aboutUsList/0"

            }, {
                active: false,
                name: "服务",
                key: "Services",
            }, {
                active: false,
                name: "新闻中心",
                key: "News",
            },
            {
                active: false,
                name: "加入我们",
                key: "Detail/joinUsList/0",
            },
        ]
    }
    navClick(item: NavItem) {
        toPage(item.key)
        let { navList } = this.state;
        navList.map(item => item.active = false);
        item.active = true;
        this.setState({
            navList: this.state.navList
        })
    }
    componentWillMount() {
        let { navList } = this.state;
        navList.map(item => item.active = window.location.hash.includes(item.key));

    }
    render() {
        let { navList } = this.state;
        return (
            <div className="NavComponent collapse navbar-collapse" id="navbarSupportedContent" >
                <ul className="navbar-nav mr-auto">
                    {navList.map((item, index) => {
                        if (item.name === '加入我们') {
                            return (<li className="nav-item" key={index}>
                                <label className={`nav-link ${item.active ? 'active' : ''}`} htmlFor="navBtn" onClick={() => window.location.href = "http://pc.caidaocloud.com:5201/index.html#/joinus?companyid=8525"} >{item.name}</label>
                            </li>)
                        }
                        return (<li className="nav-item" key={index}>
                            <label className={`nav-link ${item.active ? 'active' : ''}`} htmlFor="navBtn" onClick={() => this.navClick(item)} >{item.name}</label>
                        </li>)
                    })}

                    {/* <li className="nav-item">
                        <label className="nav-link" htmlFor="navBtn" onClick={() => { toPage('About') }}>关于我们</label>
                    </li>
                    <li className="nav-item">
                        <label className="nav-link" htmlFor="navBtn" onClick={() => { toPage('Services') }}>服务</label>
                    </li>
                    <li className="nav-item">
                        <label className="nav-link" htmlFor="navBtn" onClick={() => { toPage('News') }}>新闻中心</label>
                    </li>
                    <li className="nav-item">
                        <label className="nav-link" htmlFor="navBtn" onClick={() => { toPage('Contact') }}>联系我们</label>
                    </li> */}
                </ul>
                <label className="close navbar-toggler" htmlFor="navBtn">x</label>
            </div>
        );
    }
}

export default NavComponent;
