import React, { Component, Fragment } from 'react';
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom'
// import { container, ImageExtend, QuillWatch } from "quill-image-super-solution-module";


// import "quill/dist/quill.core.css"; import "quill/dist/quill.snow.css"; import "quill/dist/quill.bubble.css";


// 组件
import HeaderComponent from './components/Header/HeaderComponent';
import FooterComponent from './components/Footer/FooterComponent';
// 页面
import Home from './pages/Home/Home'
import About from './pages/About/About'
import Services from './pages/Services/Services'
// import Team from './pages/Team/Team'
import Contact from './pages/Contact/Contact'
import { getHome, recruitment } from './http';
import './App.scss';
import history from './router/history';
import News from './pages/News/News';
import { Detail } from './pages/Detail/Detail';

// Quill.register("modules/ImageExtend", ImageExtend);
class App extends Component {
  state = {
    globalData: {
      adList: ([] as any[]),
      casesList: ([] as any[]),
      companyNews: ([] as any[]),
      marketMews: ([] as any[]),
      partnerList: ([] as any[]),
    },
    loaded: false,
    isHome: false,
  }
  constructor(props: any) {
    super(props);








  }
  componentDidMount() {



  }
  async getHome() {
    const { data } = (await getHome()).data;
    console.log(data);
    // this.globalData = data;
    window.globalData = {
      ...window.globalData,
      ...data
    };
    window.globalData.newsList = window.globalData.marketMewsList.concat(window.globalData.companyNewsList)
    this.setState({
      globalData: data,
      loaded: true
    })
  }

  async recruitment() {
    const { data } = (await recruitment()).data
    console.log(window.globalData);
    window.globalData.joinUsList = data
  }

  async componentWillMount() {

    history.listen(({ pathname }) => {
      console.log('路由跳转')
      // console.log(location.pathname)
      window.scrollTo(0, 0)

      this.setState({
        isHome: pathname === "/Home"
      })

    })

    this.setState({
      isHome: window.location.hash.includes('Home')
    })

    window.globalData = {
      adList: [],
      casesList: [],
      companyNewsList: [],
      marketMews: [],
      partnerList: [],
      aboutUsList: [],
      marketMewsList: [],
      productServiceList: [],
      newsList: [],
      joinUsList: [],
    };
    this.recruitment();
    await this.getHome();


  }
  render() {
    console.log('app render')
    return (

      <Fragment>
        {this.state.loaded ?
          <div>
            <HeaderComponent ></HeaderComponent>

            <HashRouter>
              {
                <Switch>
                  <Route path="/Home" component={Home} />
                  <Route path="/About" component={About} />
                  <Route path="/Services" component={Services} />
                  <Route path="/News" component={News} />
                  <Route path="/Contact" component={Contact} />
                  <Route path="/Detail/:key/:index?" component={Detail} />
                  <Route exact path="/Home" component={Home} />
                  <Redirect to={"/Home"} />
                </Switch>
              }
            </HashRouter>
            <FooterComponent></FooterComponent>
          </div>

          : <div className="AppLoading">
            <div className="spinner-border" role="status" >
              <span className="sr-only">Loading...</span>
            </div>
          </div>}
      </Fragment>

    );
  }
}

export default App;
