
import axios from 'axios';
// import Qs from 'qs';
// import   './setupProxy';
// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

const config = {
  // baseURL: process.env.baseURL || process.env.apiUrl || "/career/gpa/gpwx/",
  baseURL: "http://api.zlrlzyfw.com/app/api/",
  timeout: 60 * 1000, // Timeout
  // withCredentials: true, // Check cross-site Access-Control
  // headers:{
  //   'Content-Type':'application/x-www-form-urlencoded'
  // }
  transformRequest: [function (data: any) {
    // data = Qs.stringify(data);
    return data;
  }],
  loading: true
};

const _axios = axios.create(config);
// proxy(_axios.interceptors.request)
_axios.interceptors.request.use(
  (cfg) => {
    // Do something before request is sent
    // console.log(cfg);

    if (cfg.url!.indexOf('QuestionSelect') === -1) {
      // const toast = Toast.loading({
      //   message: '加载中...',
      //   duration: 0,
      //   forbidClick: true
      // });

      // let second = 4;
      // const timer = setInterval(() => {
      //   second--;
      //   if (second == 0) {


      //     toast.message = `当前网络差...`;
      //   }

      //   if (second == -4) {
      //     clearInterval(timer);
      //     Toast.clear();
      //   }
      // }, 1000);
    }

    return cfg;
  },
  (err) => {

    // Toast.loading({
    //   message: '加载中...',
    //   forbidClick: true
    // });
    // Do something with request error
    return Promise.reject(err);
  },
);

// Add a response interceptor
_axios.interceptors.response.use(
  (res) => {
    // Do something with response data

    // Toast.clear();
    return res;
  },
  (err) => {
    console.log(err);
    // Toast(err.response.status)
    // Toast.clear();
    // Do something with response error
    return Promise.reject(err);
  },
);

// const Plugin: PluginObject<any> = {
//   install: (Vue) => {
//     Vue.$axios = _axios;
//   },
// };
// Plugin.install = (Vue) => {
//   Vue.$axios = _axios;
//   window.axios = _axios;
//   Object.defineProperties(Vue.prototype, {
//     $axios: {
//       get() {
//         return _axios;
//       },
//     },
//   });
// };

// Vue.use(Plugin);

export default _axios;

