
import React, { Component } from 'react';
import { toPage } from '../../router/history';
// import ModalComponent from '../Modal/ModalComponent';
// import Nav from '../Nav/NavComponent'
import './ServicesComponent.scss'
interface state {
    listItem: ListItem,
}
class ServicesComponent extends Component {
    state: state = {
        listItem: {
            content: "",
            updateTime: "",
            description: "",
            title: "",
            image: "",
        }
    }
    componentDidMount() {


    }

    render() {
        let productServiceList = window.globalData.productServiceList;
        // productServiceList = productServiceList.concat(productServiceList);
        // const lookMore = (item: ListItem) => {
        //     this.setState({
        //         listItem: item
        //     })
        // }
        // let { listItem } = this.state
        return (

            <div className="ServicesComponent what_we_do_section layout_padding" >
                {/* <ModalComponent item={listItem}></ModalComponent> */}
                <div className="container">
                    <h1 className="what_taital">我们服务</h1>
                    <p className="what_text_1">Services</p>

                    <p className="what_text"></p>
                    <div className="what_we_do_section_2">
                        <div className="row">
                            {
                                productServiceList.map((item, index) => {
                                    return (<div className="col-lg-3 col-sm-6" key={index} >
                                        <div className="box_main">
                                            <div className="icon_1"><img src={item.image} alt="" /></div>
                                            <h3 className="accounting_text text_flow">{item.title}</h3>
                                            <p className="lorem_text text_flow_2">{item.description || '请配置简介'}</p>
                                            <div className="moremore_bt_1" ><a href="#."  onClick={(e) => { toPage(`Detail/productServiceList/${index}`,e) }}>查看更多</a></div>
                                        </div>
                                    </div>)
                                })
                            }


                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

export default ServicesComponent;
