import React, { Component } from 'react';
import { message } from '../../http';
import './Contact.scss'
// interface Contact {
//     [x: string]: any
// }

interface State {
    [x: string]: any
    isSubmit: boolean
}
class Contact extends Component {


    state: State = {
        isSubmit: false,
        phone: '',
        address: '',
        content: '',

    }
    componentDidMount() {
        // GL版命名空间为BMapGL
        // 按住鼠标右键，修改倾斜角和角度
        console.log(BMapGL)
        const map = new BMapGL.Map("company_map");    // 创建Map实例

        const point = new BMapGL.Point(113.328439, 23.147325)
        map.centerAndZoom(point, 19);  // 初始化地图,设置中心点坐标和地图级别
        map.enableScrollWheelZoom(true);     //开启鼠标滚轮缩放
        map.setHeading(64.5);
        map.setTilt(73);

        const marker = new BMapGL.Marker(point);  // 创建标注
        map.addOverlay(marker);              // 将标注添加到地图中
        const opts = {
            width: 200,     // 信息窗口宽度
            height: 100,     // 信息窗口高度
            title: "广州智领人力资源服务有限公司", // 信息窗口标题
            message: "我们在这里"
        }
        const infoWindow = new BMapGL.InfoWindow("地址：广州市天河区 天河北路179号1702室", opts);  // 创建信息窗口对象 
        marker.addEventListener("click", function () {
            map.openInfoWindow(infoWindow, point); //开启信息窗口
        });
        map.openInfoWindow(infoWindow, point);
    }
    async submit() {
        let { phone, content, address } = this.state;
        let rule: { [x: string]: string } = {
            phone: '请输入手机号码',
            address: "请输入地址",
            content: '请输入留言',
        }
        for (let key in rule) {
            if (!this.state[key]) {
                alert(rule[key]);
                return
            }
        }

        if (!(/^1[0-9]{10}$/).test(phone)) {
            console.log(phone)
            alert('请输入正确的手机号码')
            return
        }
        const { data } = await message({ phone, content, address, })
        console.log(data);
        if (data.code === 200) {
            alert('提交成功!我们将会联系您~');
            this.setState({
                isSubmit: true,
            })
        }

    }

    getValue(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
        console.log(this.state.form);
        let { value, name } = e.target;
        this.setState({
            [name]: value
        })
    }
    render() {
        let { isSubmit, phone, address, content } = this.state;
        return (
            <div className="contact_section layout_padding">
                <div className="container-fluid">
                    <h1 className="what_taital">联系我们</h1>
                    <p className="amet_text">Contact us</p>
                    <div className="contact_section2">
                        <div className="row">
                            <div className="col-md-6 padding_left_0">
                                {
                                    isSubmit ? <div style={{ textAlign: "center" }}>
                                        <p>
                                            <h3>已提交,期待与您合作愉快~ </h3>
                                        </p>
                                        <p className="submit_tip" onClick={() => { this.setState({ isSubmit: false }) }}>信息输入错误?点此重新填写</p>
                                    </div> :
                                        <div className="mail_section">
                                            {/* <input type="" className="mail_text_1" placeholder="Name" name="phone" /> */}
                                            <input type="" value={phone} className="mail_text_1" placeholder="手机号码" name="phone" onChange={(e) => this.getValue(e)} />
                                            <input type="" value={address} className="mail_text_1" placeholder="您的地址" name="address" onChange={(e) => this.getValue(e)} />
                                            <textarea value={content} className="massage_text" placeholder="留言" rows={5} id="comment" name="content" onChange={(e) => this.getValue(e)}></textarea>
                                            <div className="send_bt" onClick={() => this.submit()}><div className="submit">提交</div></div>
                                        </div>

                                }


                            </div>
                            <div className="col-md-6 padding_0">
                                <div className="map-responsive" style={{ border: 0, width: "100%", height: "100%", minHeight: '300px' }}>
                                    <div id="company_map" style={{ border: 0, width: "100%", height: "100%" }}></div>
                                    {/* <iframe src="https://www.google.com/maps/embed/v1/place?key=AIzaSyA0s1a7phLN0iaD6-UE7m4qP-z21pH0eSc&amp;q=Eiffel+Tower+Paris+France"   style={{ border: 0, width: "100%",height:"600px" }} allowFullScreen title="map"></iframe> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Contact;
