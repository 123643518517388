import React, { Component } from "react";
// import Swiper from 'swiper';
import './Banner.scss'
interface props {
    list: any[],
}
class Banner extends Component<props> {

    // constructor(props: { list: any[] }) {
    //     super(props)

    // }
    componentDidMount() {
        // 
        setTimeout(() => {
            let banner = ($("#carouselExampleIndicators") as any)

            banner.carousel({
                interval: 5000
            })

            var swiper = new Swiper('.banner', {
                autoplay: {
                    delay: 5000,
                    disableOnInteraction: false,
                },
                speed: 700,
                allowTouchMove: true,
                // lazy: {
                //     loadPrevNext: true,
                //     loadPrevNextAmount: 3,
                // },
                // autoHeight: true,
                centeredSlides: true,
                loop: true,
                // on: {
                //     slideChangeTransitionEnd: function () {
                //         this.slides.transition(this.params.autoplay.delay + this.params.speed).transform('translate3d(-60px, 0, 0)');
                //     },
                //     slideChangeTransitionStart: function () {
                //         this.slides.transition(this.params.speed).transform('translate3d(0, 0, 0)');
                //     },
                // } as any,
                pagination: {
                    el: '.banner .swiper-pagination',
                    clickable: true,
                    // renderBullet: function (index: any, className: string) {
                    //     return '<div class="' + className + '"><span></span><i></i></div>';
                    // },
                },
            });
            window.onresize = function () {
                swiper.update();
            }
        })
    }
    render() {
        let { list } = this.props;
        // list = list.concat(list);
        return (

            <div className="swiper-container banner">
                <div className="swiper-wrapper">
                    {list.map((item, index) =>
                        <div className="swiper-slide" key={index}>
                            {/* <div className="title">{item.title}</div> */}
                            {/* <img data-src={item.image} data-srcset={item.image} className="swiper-lazy" alt="" /> */}
                            <img src={item.image} className="swiper-lazy" alt="" />
                            {/* <div className="swiper-lazy-preloader"></div> */}
                        </div>)
                    }

                    {/* <div className="swiper-slide">
                        <div className="title">#02 _ A tatami-matted hallway</div><img data-src="img/picture-2.jpg" data-srcset="img/picture-2-2x.jpg 2x" className="swiper-lazy" />
                        <div className="swiper-lazy-preloader"></div>
                    </div>
                    <div className="swiper-slide">
                        <div className="title">#03 _ A guestroom</div><img data-src="img/picture-3.jpg" data-srcset="img/picture-3-2x.jpg 2x" className="swiper-lazy" />
                        <div className="swiper-lazy-preloader"></div>
                    </div>
                    <div className="swiper-slide">
                        <div className="title">#04 _ The ochanoma lounge</div><img data-src="img/picture-4.jpg" data-srcset="img/picture-4-2x.jpg 2x" className="swiper-lazy" />
                        <div className="swiper-lazy-preloader"></div>
                    </div>
                    <div className="swiper-slide">
                        <div className="title">#05 _ Reception</div><img data-src="img/picture-5.jpg" data-srcset="img/picture-5-2x.jpg 2x" className="swiper-lazy" />
                        <div className="swiper-lazy-preloader"></div>
                    </div>
                    <div className="swiper-slide">
                        <div className="title">#06 _ The entrance garden</div><img data-src="img/picture-6.jpg" data-srcset="img/picture-6-2x.jpg 2x" className="swiper-lazy" />
                        <div className="swiper-lazy-preloader"></div>
                    </div> */}
                </div>
                <div className="swiper-pagination"></div>
                {/* <div className="swiper-button-prev"></div> */}
                {/* <div className="swiper-button-next"></div> */}
            </div>
        )
    }
}

export default Banner