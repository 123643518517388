import { chn_mon_arr } from "./data";


export const getMonth = (time: string) => {
    let d = new Date(time);
    let m = d.getMonth();
    return chn_mon_arr[m]
}
export const add0 = (m: number) => {
    return m < 10 ? '0' + m : m
}
export const getDay = (time: string) => {
    let d = new Date(time);
    let date = d.getDate();
    return add0(date)
}