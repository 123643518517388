import React, { Component } from 'react';
import { toPage } from '../../router/history';

interface props {
    item: AboutItem
    index: number
}

class AboutComponent extends Component<props> {
    // constructor (){},
    constructor(props: any) {
        super(props)
        console.log('AboutComponent')
        window.location.href = 'http://pc.caidaocloud.com:5201/index.html#/joinus?companyid=8525'
    }
    render() {
        let { item, index } = this.props;
        return (

            <div className="AboutComponent services_section layout_padding">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8">
                            <h1 className="services_taital">{item.title}</h1>
                            <p className="services_text">{item.description || '未配置简介'}</p>
                            <div className="moremore_bt"><a href="#project" onClick={(e) => { toPage(`Detail/aboutUsList/${index}`, e) }}>查看更多</a></div>
                        </div>
                        <div className="col-md-4">
                            <div><img src={item.image} className="image_1" alt="" /></div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

export default AboutComponent;
