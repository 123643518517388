import { createHashHistory } from 'history';
const history = createHashHistory();

export const toPage = (path: string, e?: React.MouseEvent) => {
    console.log(path)
    // a 标签阻止默认事件
    // console.log(e);
    e && e.preventDefault();
    history.push(`/${path}`)
    // history

    return
}

export default history