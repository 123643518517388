import React, { Component } from "react";
import './PartnerComponent.scss';

interface props {
    list: any[]
}

export default class Parner extends Component<props> {
    componentDidMount() {
        let obj = {
            slidesPerView: 3,
            slidesPerColumn: 2,
            spaceBetween: 30,
        }
        if (($(document.documentElement) as any).width() < 600) {
            obj = {
                slidesPerView: 2,
                slidesPerColumn: 2,
                spaceBetween: 10,
            }
        }


        // var swiper = new Swiper('.swiper-container', {
        //     spaceBetween: 30,
        //     centeredSlides: true,
        //     autoplay: {
        //       delay: 2500,
        //       disableOnInteraction: false,
        //     },
        //     pagination: {
        //       el: '.swiper-pagination',
        //       clickable: true,
        //     },
        //     navigation: {
        //       nextEl: '.swiper-button-next',
        //       prevEl: '.swiper-button-prev',
        //     },
        //   });

        new Swiper('.partner-box', {
            ...obj,
            autoplay: {
                delay: 3000,
                disableOnInteraction: false,

            },
            // loop: true,
            pagination: {
                el: '.partner-box .swiper-pagination',
                clickable: true,
            },
        });

    }
    render() {
        let { list } = this.props;
        if (list.length % 2 !== 0) {
            list.push(list[0]);
        }
        // list = list.concat(list);
        // list = list.concat(list);
        // list.pop()
        // list = list.concat(list);
        // list = list.concat(list);
        // list = list.concat(list);
        return (
            <div>

                <h1 className="what_taital">合作客户</h1>
                <p className="what_text_1">Cooperative partner</p>

                <div className="swiper-container partner-box layout_padding">

                    <div className="swiper-wrapper" >
                        {list.map((item, index) =>
                            <div className="swiper-slide" key={index}>
                                <img src={item.image} alt="" />
                            </div>
                        )}

                    </div>

                    <div className="swiper-pagination"></div>
                    <span className="swiper-notification" aria-live="assertive" aria-atomic="true"></span></div>
            </div>
        )
    }
}

