
import React, { Component } from 'react';
// import history, { toPage } from '../../router/history';
// import Banner from '../Banner/Banner';
import LogoComponent from '../Logo/LogoComponent';
import NavComponent from '../Nav/NavComponent';
import './HeaderComponent.scss'

interface props {
    // isHome: boolean,
}

interface state {
    companyNews: any[],
    adList: any[],
}
class HeaderComponent extends Component<props> {
    state: state = {

        companyNews: [],
        adList: [],
    }
    // constructor(props: any) {
    //     super(props);
    //     console.log(props)


    // };

    componentDidMount() {

        this.setState({
            companyNews: window.globalData.companyNewsList,
            adList: window.globalData.adList
        })
        console.log({btn:document.querySelector('#navBtn')});
    }
    render() {
        // let { isHome } = this.props
        return (
            <div>
                <div className="header_section">
                    {/*{ width: isHome ? '50%' : '100%' }*/}
                    <div className="header_left" >
                        <nav className="navbar navbar-expand-lg navbar-light fixed-top">
                            <LogoComponent></LogoComponent>
                            <button id="navBtn" className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"></span>
                            </button>
                            <NavComponent></NavComponent>
                            <div className="weixin_box">
                                <svg className="icon weixin" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1372" width="30" height="30">
                                    <path d="M691.230034 363.055736c4.50664 0 8.985652 0.100284 13.443174 0.267083-28.387537-129.98547-162.365948-228.411153-323.384202-228.411153-181.674713 0-328.949964 125.291564-328.949964 279.844569 0 90.298586 50.28629 170.592306 128.319527 221.76171l-32.865525 96.941889 108.828614-59.677171c38.45073 13.407358 80.538291 20.818141 124.666324 20.818141 8.506744 0 16.937764-0.274246 25.28078-0.814552-9.205663-24.38027-14.193257-50.360991-14.193257-77.331249C392.377552 476.507648 526.177907 363.055736 691.230034 363.055736zM494.810502 273.250384c26.828019 0 48.577369 21.748327 48.577369 48.577369 0 26.828019-21.748327 48.577369-48.577369 48.577369s-48.577369-21.748327-48.577369-48.577369C446.234157 294.998711 467.982484 273.250384 494.810502 273.250384zM268.822538 370.404098c-26.828019 0-48.577369-21.748327-48.577369-48.577369 0-26.828019 21.748327-48.577369 48.577369-48.577369s48.577369 21.748327 48.577369 48.577369C317.399907 348.654748 295.65158 370.404098 268.822538 370.404098z" p-id="1373" fill="#001e72"></path><path d="M968.963523 616.456027c0-130.059148-124.345006-235.492432-277.733489-235.492432S413.497569 486.396879 413.497569 616.456027s124.345006 235.492432 277.733489 235.492432c32.206516 0 63.123666-4.66423 91.88778-13.211906l94.959745 52.070936-27.598568-81.407078C922.118612 766.810407 968.963523 696.275957 968.963523 616.456027zM604.457762 582.663386c-21.48022 0-38.892799-17.412578-38.892799-38.892799 0-21.48022 17.412578-38.892799 38.892799-38.892799 21.48022 0 38.892799 17.412578 38.892799 38.892799C643.35056 565.250808 625.937982 582.663386 604.457762 582.663386zM785.395694 582.663386c-21.48022 0-38.892799-17.412578-38.892799-38.892799 0-21.48022 17.412578-38.892799 38.892799-38.892799 21.48022 0 38.892799 17.412578 38.892799 38.892799C824.288492 565.250808 806.874891 582.663386 785.395694 582.663386z" p-id="1374" fill="#001e72"></path>
                                </svg>
                                <div className="weixin_img">
                                    <p>扫一扫,关注</p>
                                    <img src="./images/weixin.jpg" alt="" />
                                </div>
                                <div className="tel">
                                    联系电话:18928901857
                                </div>
                            </div>
                        </nav>
                        {/* {
                        isHome &&
                        <div className="banner_main" >
                            <h1 className="banner_taital">{this.state.companyNews[0].title}</h1>
                            <p className="banner_text">{this.state.companyNews[0].content}</p>
                            <div className="btn_main">
                                <div className="more_bt"><a href="#." onClick={(e) => { toPage('Contact', e) }}>查看更多</a></div>
                                <div className="contact_bt"><a href="#." onClick={(e) => { toPage('Contact', e) }}>联系我们</a></div>
                            </div>
                        </div>
                    } */}
                    </div>

                    {/* {
                    isHome &&
                    <div className="header_right" >
                        <img src="images/banner-img.png" className="banner_img" />
                    </div>
                } */}

                </div>
                {/* <div style={{ display: isHome ? 'block' : 'none' }}><Banner list={this.state.adList}></Banner></div> */}
            </div>
        );
    }
}

export default HeaderComponent;
