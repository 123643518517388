import React, { Component } from "react"
import { toPage } from "../../router/history";
import './DetailComponent.scss'

interface props {
    list: any[]
    name: string
    id: number,
    index: number
    listKey: string,
}
export class DetailComponent extends Component<props> {

    render = () => {
        let { list, name, listKey, index } = this.props;
        const detailIndex = +index
        name += (Math.random() * 10000).toFixed(0);
        console.log(listKey, detailIndex)
        return (
            <div className="DetailComponent">
                <div className="row">
                    <div className="col-0" >

                        <div className="col-1 nav flex-column side-nav" >
                            {list.map((item, index) => {
                                let label = name + index;
                                let id = `v-pills-${label}-tab`
                                let href = `#v-pills-${label}`
                                let ariaControls = `v-pills-${label}`
                                return <a href={`#/Detail/${listKey}/${index}`} className={`nav-link text_flow_3  ${index === +detailIndex ? 'active' : ''}`} key={index} onClick={(e) => {
                                    // e.preventDefault()
                                    // toPage(`Detail/${listKey}/${index}`)
                                }} >{item.title}</a>
                            })}

                        </div>
                    </div>
                    <div className="col-12">
                        <div className="tab-content" >

                            {list.map((item, index) => {
                                let label = name + index;
                                let id = `v-pills-${label}`
                                // if (index !== detailIndex) return null
                                return <div className={`tab-pane ${index === detailIndex && 'show'}`} key={index}>

                                    {/*<h1>{item.title}</h1>*/}
                                    <div className="ql-snow ql-editor">
                                        <div dangerouslySetInnerHTML={{ __html: item.content }}></div>
                                    </div>
                                </div>
                            })}
                            {/* <div className="tab-pane fade" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">Messages</div>
                            <div className="tab-pane fade" id="v-pills-messages" role="tabpanel" aria-labelledby="v-pills-messages-tab">Messages</div>
                            <div className="tab-pane fade" id="v-pills-settings" role="tabpanel" aria-labelledby="v-pills-settings-tab">Settings</div> */}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}