import React, { Component } from "react"
import { match } from "react-router-dom";
import { DetailComponent } from "../../components/Detail/DetailComponent";
import Contact from "../Contact/Contact";
import './Detail.scss'
// adList: ListItem[],
// casesList: ListItem[],
// companyNewsList: ListItem[],
// marketMews: ListItem[],
// partnerList: ListItem[],
// aboutUsList: AboutItem[],
// marketMewsList: ListItem[],
// productServiceList: ListItem[],
interface Macth {
    params: {
        key: string
        id: number
        index: number
    }
}
interface props {
    list: any[]
    name: string

    match: Macth
}
export class Detail extends Component<props> {

    render = () => {
        // let { list, name } = this.props;
        // name += Math.random();
        console.log(this.props.match.params)
        let { key, id = 0, index } = this.props.match.params;
        let list = window.globalData[key];
        return (
            <div className="Detail">
                <DetailComponent name={key} list={list} id={index} index={index} listKey={key}></DetailComponent>
                {key === 'aboutUsList' ? <Contact></Contact> : ''}
            </div>
        )
    }
}