
import React, { Component } from 'react';
import { toPage } from '../../router/history';
import { getDay, getMonth } from '../../utils';
import './NewsComponent.scss'
interface props {
    list: ListItem[]
    title: string
    name: string
}
class NewsComponent extends Component<props> {

    componentWillMount() {
        let { name } = this.props;
        setTimeout(() => {
            let bg = document.querySelector(`.${name} .item-bg`) as HTMLElement;

            if (($(document.documentElement) as any).width() > 800) {
                $(document).on("mouseover", `.${name} .news__item`, function (_event, _element) {

                    let newsItem = document.querySelectorAll(`.${name} .news__item`);
                    newsItem.forEach(function (element, index) {
                        element.addEventListener('mouseover', function () {

                            let x = element.getBoundingClientRect().left;
                            // let y = element.getBoundingClientRect().top;
                            let width = element.getBoundingClientRect().width;
                            let height = element.getBoundingClientRect().height;

                            $(`.${name} .item-bg`).addClass('active');
                            $(`.${name} .news__item`).removeClass('active');
                            // $('.news__item').removeClass('active');

                            if (bg) {
                                bg.style.width = width + 'px';
                                bg.style.height = height + 'px';
                                // bg.style.transform = 'translateX(' + x + 'px ) translateY(' + y + 'px)';
                                bg.style.transform = 'translateX(' + x + 'px )';
                            }

                        });

                        element.addEventListener('mouseleave', function () {
                            $(`.${name} .item-bg`).removeClass('active');
                            $(`.${name} .news__item`).removeClass('active');
                        });

                    });

                });
            }


            let swiper = new Swiper(`.${name} .news-slider`, {
                effect: 'coverflow',
                grabCursor: true,
                loop: true,
                centeredSlides: true,
                keyboard: true,
                spaceBetween: 0,
                slidesPerView: 'auto',
                speed: 300,
                autoplay: {
                    delay: 5000,
                    disableOnInteraction: false,
                },
                coverflowEffect: {
                    rotate: 0,
                    stretch: 0,
                    depth: 0,
                    modifier: 3,
                    slideShadows: false
                },
                breakpoints: {
                    480: {
                        spaceBetween: 0,
                        centeredSlides: true
                    }
                },
                simulateTouch: true,
                navigation: {
                    nextEl: `.${name} .news-slider-next`,
                    prevEl: `.${name} .news-slider-prev`
                },
                pagination: {
                    el: `.${name} .news-slider__pagination`,
                    clickable: true
                },
                on: {
                    init: function () {
                        let activeItem = document.querySelector(`.${name} .swiper-slide-active`) as Element;

                        let sliderItem = activeItem.querySelector(`.${name} .news__item`) as Element;;

                        $(`.${name} .swiper-slide-active .news__item`).addClass('active');

                        let x = sliderItem.getBoundingClientRect().left;
                        let y = sliderItem.getBoundingClientRect().top;
                        let width = sliderItem.getBoundingClientRect().width;
                        let height = sliderItem.getBoundingClientRect().height;


                        $(`.${name} .item-bg`).addClass('active');

                        bg.style.width = width + 'px';
                        bg.style.height = height + 'px';
                        bg.style.transform = 'translateX(' + x + 'px )';
                        // bg.style.transform = 'translateX(' + x + 'px ) translateY(' + y + 'px)';
                    }
                }
            });

            swiper.on('touchEnd', function () {
                $(`.${name} .news__item`).removeClass('active');
                $(`.${name} .swiper-slide-active .news__item`).addClass('active');
            });

            swiper.on('slideChange', function () {
                $(`.${name} .news__item`).removeClass('active');
            });

            swiper.on('slideChangeTransitionEnd', function () {
                $(`.${name} .news__item`).removeClass('active');
                let activeItem = document.querySelector(`.${name} .swiper-slide-active`) as Element;;

                let sliderItem = activeItem.querySelector(`.${name} .news__item`) as Element;;

                $(`.${name} .swiper-slide-active .news__item`).addClass('active');

                let x = sliderItem.getBoundingClientRect().left;
                // let y = sliderItem.getBoundingClientRect().top;
                let width = sliderItem.getBoundingClientRect().width;
                let height = sliderItem.getBoundingClientRect().height;


                $(`.${name} .item-bg`).addClass('active');

                bg.style.width = width + 'px';
                bg.style.height = height + 'px';
                bg.style.transform = 'translateX(' + x + 'px )';
                // bg.style.transform = 'translateX(' + x + 'px ) translateY(' + y + 'px)';
            });
        })
    }
    render() {
        let { list, title, name } = this.props;
        // list = list.concat(list);

        return (
            <div className={`news ${name} layout_padding`} >
                <h1 className="what_taital">{title}</h1>
                <p className="what_text_1">{name} News</p>

                <div className="wrapper">

                    {/* <div className="background">
                        <img src="images/background.jpg" alt="" />
                    </div> */}

                    <div className="item-bg"></div>
                    <div className="news-slider">
                        <div className="news-slider__wrp swiper-wrapper">
                            {list.map((item, index) =>
                                <div className="news-slider__item swiper-slide" key={index} onClick={() => { toPage(`Detail/newsList/${index}`) }}>
                                    <div className="news__item">
                                        <div className="news-date">
                                            <span className="news-date__title">{getDay(item.updateTime.split(' ')[0])}</span>
                                            <span className="news-date__txt">{getMonth(item.updateTime.split(' ')[0])}</span>
                                        </div>
                                        <div className="news__title text_flow">
                                            {/* SNH48<br />盛夏好声音 */}
                                            {item.title}
                                        </div>

                                        <p className="news__txt text_flow_3">
                                            {item.description}
                                        </p>

                                        <div className="news__img">
                                            <img src={item.image} alt="news" />
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>

                        <div className="news-slider__ctr">

                            <div className="news-slider__arrows">
                                <button className="news-slider__arrow news-slider-prev">
                                    <span className="icon-font">
                                        <svg className="icon icon-arrow-left">
                                            <use xlinkHref="#icon-arrow-left"></use>
                                        </svg>
                                    </span>
                                </button>
                                <button className="news-slider__arrow news-slider-next">
                                    <span className="icon-font">
                                        <svg className="icon icon-arrow-right"><use xlinkHref="#icon-arrow-right"></use></svg>
                                    </span>
                                </button>
                            </div>

                            <div className="news-slider__pagination" ></div>

                        </div>

                    </div>

                </div>
                <svg aria-hidden={true} id="icon">
                    <defs>
                        <symbol id="icon-arrow-left" viewBox="0 0 32 32">
                            <title>arrow-left</title>
                            <path d="M0.704 17.696l9.856 9.856c0.896 0.896 2.432 0.896 3.328 0s0.896-2.432 0-3.328l-5.792-5.856h21.568c1.312 0 2.368-1.056 2.368-2.368s-1.056-2.368-2.368-2.368h-21.568l5.824-5.824c0.896-0.896 0.896-2.432 0-3.328-0.48-0.48-1.088-0.704-1.696-0.704s-1.216 0.224-1.696 0.704l-9.824 9.824c-0.448 0.448-0.704 1.056-0.704 1.696s0.224 1.248 0.704 1.696z"></path>
                        </symbol>
                        <symbol id="icon-arrow-right" viewBox="0 0 32 32">
                            <title>arrow-right</title>
                            <path d="M31.296 14.336l-9.888-9.888c-0.896-0.896-2.432-0.896-3.328 0s-0.896 2.432 0 3.328l5.824 5.856h-21.536c-1.312 0-2.368 1.056-2.368 2.368s1.056 2.368 2.368 2.368h21.568l-5.856 5.824c-0.896 0.896-0.896 2.432 0 3.328 0.48 0.48 1.088 0.704 1.696 0.704s1.216-0.224 1.696-0.704l9.824-9.824c0.448-0.448 0.704-1.056 0.704-1.696s-0.224-1.248-0.704-1.664z"></path>
                        </symbol>
                    </defs>
                </svg>
            </div>

        );
    }

}

export default NewsComponent;
