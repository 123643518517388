import React from 'react';
import { Component } from 'react';

// import AboutComponent from '../../components/About/AboutComponent';
// import LogoComponent from '../../components/Logo/LogoComponent';
// import NavComponent from '../../components/Nav/NavComponent';
import ServicesComponent from '../../components/Services/ServicesComponent';
// import TeamComponent from '../../components/Team/TeamComponent';
import Partner from '../../components/Partner/PartnerComponent';



import './Home.scss'
// import Case from '../../components/Case/CaseComponent';
import NewsComponent from '../../components/News/NewsComponent';
// import ModalComponent from '../../components/Modal/ModalComponent';
import Banner from '../../components/Banner/Banner';

interface state {
  aboutItem: AboutItem,
  casesList: ListItem[],
  partnerList: ListItem[],
  marketMewsList: ListItem[],
  companyNewsList: ListItem[],
  adList:ListItem[],
}
class Home extends Component {
  state: state = {
    aboutItem: {},
    casesList: [],
    partnerList: [],
    marketMewsList: [],
    companyNewsList: [],
    adList:[],
  }
  constructor(props: any) {
    super(props);
    console.log(props)

  }
  componentWillMount() {
    console.log('componentWillMount');
    console.log(window.globalData);
    this.setState({
      aboutItem: window.globalData.aboutUsList[0],
      casesList: window.globalData.casesList,
      partnerList: window.globalData.partnerList,
      marketMewsList: window.globalData.marketMewsList,
      companyNewsList: window.globalData.companyNewsList,
      adList: window.globalData.adList,
    })
  }
  render() {
    let { partnerList, marketMewsList, companyNewsList } = this.state
    // casesList = casesList.concat(casesList)
    // casesList = casesList.concat(casesList)

    // partnerList = partnerList.concat(partnerList);
    // partnerList = partnerList.concat(partnerList);
    return (
      <div className="Home">
        <Banner list={this.state.adList}></Banner>
        <ServicesComponent></ServicesComponent>

        <div className="project_section_2 layout_padding" id="project" style={{ marginTop: '90px' }}>
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-sm-6">
                <div className="icon_1"><img src="images/icon-3.png" alt="" /></div>
                <h3 className="accounting_text_1">1000+</h3>
                <p className="yers_text">年营业额</p>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div className="icon_1"><img src="images/icon-4.png" alt="" /></div>
                <h3 className="accounting_text_1">20000+</h3>
                <p className="yers_text">已交付项目</p>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div className="icon_1"><img src="images/icon-2.png" alt="" /></div>
                <h3 className="accounting_text_1">10000+</h3>
                <p className="yers_text">满足用户</p>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div className="icon_1"><img src="images/icon-1.png" alt="" /></div>
                <h3 className="accounting_text_1">1500+</h3>
                <p className="yers_text">专业服务</p>
              </div>
            </div>
          </div>
        </div>

        <NewsComponent title={'新闻'} list={marketMewsList.concat(companyNewsList)} name={'market'}></NewsComponent>
        {/* <NewsComponent title={'公司新闻'} list={companyNewsList} name={'company'}></NewsComponent> */}



        {/* <AboutComponent item={aboutItem}></AboutComponent> */}
        {/* <Case></Case> */}


        <Partner list={partnerList}></Partner>
        {/* <!--project section end --> */}
        {/* <TeamComponent></TeamComponent> */}
        {/* <!--client section start --> */}
        <div className="client_section layout_padding">
          <div className="container">
            <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
              <ol className="carousel-indicators">
                <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"></li>
                <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                {/* <li data-target="#carouselExampleIndicators" data-slide-to="2"></li> */}
              </ol>
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <h1 className="what_taital">公司资质</h1>
                  <div className="client_section_2 layout_padding">
                    <ul>
                      <li><img src="images/round-1.png" className="round_1" alt="" /></li>
                      <li><img src="images/zizhi1.png" className="image_11" alt="" /></li>
                      <li><img src="images/round-2.png" className="round_2" alt="" /></li>
                    </ul>
                    {/* <p className="dummy_text">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem</p> */}
                  </div>
                </div>
                <div className="carousel-item">
                  <h1 className="what_taital">公司资质</h1>
                  <div className="client_section_2 layout_padding">
                    <ul>
                      <li><img src="images/round-1.png" className="round_1" alt="" /></li>
                      <li><img src="images/zizhi2.png" className="image_11" alt="" /></li>
                      <li><img src="images/round-2.png" className="round_2" alt="" /></li>
                    </ul>
                    {/* <p className="dummy_text">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem</p> */}
                  </div>
                </div>
                {/* <div className="carousel-item">
                  <h1 className="what_taital">what is syas our clients</h1>
                  <div className="client_section_2 layout_padding">
                    <ul>
                      <li><img src="images/round-1.png" className="round_1" /></li>
                      <li><img src="images/img-11.png" className="image_11" /></li>
                      <li><img src="images/round-2.png" className="round_2" /></li>
                    </ul>
                    <p className="dummy_text">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem</p>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>

        {/* 市场新闻 */}


        {/* <div className="container">
          <h1 className="what_taital">市场动态</h1>
          {
            marketMewsList.map((item, index) => {
              return (<div className="media" key={index}>
                <img src={item.image} className="mr-3" alt="..." />
                <div className="media-body">
                  <h5 className="mt-0">{item.title}</h5>
                  {item.description}
                </div>
              </div>)
            })
          }

        </div> */}
        {/* <!--client section end --> */}
        <div style={{ clear: "both" }}></div>
      </div>
    );
  }
}

export default Home;
