import React, { Component } from 'react';
import AboutComponent from '../../components/About/AboutComponent';
interface state {
    AboutList: JSX.Element[],
    aboutUsList: AboutItem[]
}
class About extends Component {
    state: state = {
        AboutList: [],
        aboutUsList: []
    }

    componentDidMount() {

        this.setState({

            AboutList: window.globalData.aboutUsList.map((item,index) => {
                return <AboutComponent item={item} key={item.id} index={index}></AboutComponent>
            })
        })
    }
    // constructor(props: any) {
    //     super(props)

    // }


    render() {
        return (
            <div className="About" >
                { this.state.AboutList}
            </div>
        );
    }
}

export default About;
