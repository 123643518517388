import React from 'react';
import Nav from '../../components/Nav/NavComponent'
import ServicesComponent from '../../components/Services/ServicesComponent';
function Home() {
    return (
        <div className="Services">
            <ServicesComponent></ServicesComponent>
        </div>
    );
}

export default Home;
