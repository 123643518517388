import React, { } from 'react';
// import { Message } from '../../http/index.d';

import { message } from '../../http';
import { toPage } from '../../router/history';

function FooterComponent() {
    let phone = ''
    let content = ''
    function getNumber(e: React.ChangeEvent<HTMLInputElement>) {
        // console.log(e.target.value);
        phone = e.target.value
    }
    function getContent(e: React.ChangeEvent<HTMLInputElement>) {
        // console.log(e.target.value);
        content = e.target.value
    }


    async function submit() {
        if (!phone) {
            alert('请输入手机号码')
            return
        }
        if (!(/^1[0-9]{10}$/).test(phone)) {
            console.log(phone)
            alert('请输入正确的手机号码')

            return
        }
        const { data } = await message({ phone, content, })
        console.log(data);
        if (data.code === 200) {
            alert('提交成功!');
        }

    }
    return (

        <div className="footer_section layout_padding margin_top_90">
            <div className="container">
                <div className="row">
                    <div className="col-lg-3 col-sm-6">
                        <h4 className="about_text">关于智领</h4>
                        <div className="location_text" onClick={() => { toPage('Contact') }}><img src="images/map-icon.png" /><span className="padding_left_15">公司地址：广州市天河区天河北路179号尚层国际1702
                        </span></div>
                        <div className="location_text"><img src="images/call-icon.png" /><span className="padding_left_15">18928901857</span></div>
                        <div className="location_text"><img src="images/mail-icon.png" /><span className="padding_left_15">zlrlqhy@163.com</span></div>
                    </div>
                    <div className="col-lg-3 col-sm-6">
                        <h4 className="about_text">关于智领</h4>
                        <p className="dolor_text">广州智领人力资源服务有限公司是拥有一支经验丰富，专注人力资本顾问团队的人力资源公司。</p>
                    </div>
                    <div className="col-lg-3 col-sm-6">
                        <h4 className="about_text">微信公众号</h4>
                        <div className="footer_images">
                            <img src="./images/weixin.jpg" style={{ width: "200px" }} alt="" />
                            {/* <div className="footer_images_left">
                                <div className="image_12"><img src="images/img-12.png" /></div>
                                <div className="image_12"><img src="images/img-12.png" /></div>
                                <div className="image_12"><img src="images/img-12.png" /></div>
                            </div>
                            <div className="footer_images_right">
                                <div className="image_12"><img src="images/img-12.png" /></div>
                                <div className="image_12"><img src="images/img-12.png" /></div>
                                <div className="image_12"><img src="images/img-12.png" /></div>
                            </div> */}
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-6">
                        <h4 className="about_text">你的联系方式</h4>
                        <input type="text" className="mail_text" placeholder="请输入手机号码" name="Enter your email" onChange={(e) => getNumber(e)} />
                        <input type="text" className="mail_text" placeholder="请输入留言" name="Enter your email" onChange={(e) => getContent(e)} />
                        <div className="subscribe_bt" onClick={() => { submit() }}><a href="" onClick={(e) => e.preventDefault()}>提交</a></div>
                        <div className="footer_social_icon">
                            {/* <ul>
                                <li><a href="#"><img src="images/fb-icon1.png" /></a></li>
                                <li><a href="#"><img src="images/twitter-icon1.png" /></a></li>
                                <li><a href="#"><img src="images/linkedin-icon1.png" /></a></li>
                                <li><a href="#"><img src="images/youtub-icon1.png" /></a></li>
                            </ul> */}
                        </div>
                    </div>
                </div>
                {/* <!-- copyright section start --> */}
                <div className="copyright_section">
                    <div className="copyright_text">Copyright &copy; 2020.广州智领人力资源服务有限公司.<a target="_blank" href="http://beian.miit.gov.cn">粤ICP备2020100802号-1</a></div>
                </div>
                {/* <!-- copyright section end --> */}
            </div>

        </div>

    );
}

export default FooterComponent;
