import React, { Component } from 'react';
import NewsComponent from '../../components/News/NewsComponent';
interface state {
    marketMewsList: ListItem[]
    companyNewsList: ListItem[]
}
class News extends Component {
    state: state = {
        marketMewsList: [],
        companyNewsList: [],
    }
    componentWillMount() {
        this.setState({
            marketMewsList: window.globalData.marketMewsList,
            companyNewsList: window.globalData.companyNewsList,
        })
    }
    render() {
        let { marketMewsList, companyNewsList } = this.state;
        let newsList = marketMewsList.concat(companyNewsList)
        return (
            <div className="News" >
                <NewsComponent title={'新闻'} list={newsList} name={'market'}></NewsComponent>
                {/* <NewsComponent title={'公司新闻'} list={companyNewsList} name={'company'}></NewsComponent> */}
            </div>

        );
    }

}

export default News;
