// import qs from 'qs';
import axios from './axios';
import { Message } from './index.d'
// import {message} from 'ant-design-vue'; // 这是我引入的antd的组件库,为了方便弹出吐司

export function getHome() {
    return axios.get('home', {

    })
}


export function message(data: Message) {
    //     参数说明（必填）：
    // phone："联系电话"
    // address：“地址”
    // content：“内容"
    let str = JSON.stringify(data);
    return axios.post('message', str,
        {

            headers: {
                'Content-Type': 'application/json;charset=UTF-8',

            },
            // dataType: "JSON",
        }
    )
}


export function recruitment() {
    return axios.get('recruitment')
}

